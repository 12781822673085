<template>
    <main id="liste" v-cloak>
        <HeaderTab :title="$t('menu.congelation.congelation')" :swiper_tabs="swiper_tabs" />

        <div id="content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <div>
                            <router-view></router-view>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>


<script type="text/javascript">
    import Navigation from "@/mixins/Navigation.js";

	export default {
		name: "Congelation",
		mixins: [Navigation],
		data () {
			return {
                swiper_tabs: [{
                        id: 'not_inventoried',
						label: 'menu.congelation.not_inventoried',
						active: false,
                        href : 'CongelationNotInventoried'
					},
                    {
                        id: 'prestockage',
                        label: 'menu.congelation.prestockage',
                        active: false,
                        href : 'CongelationPrestockage'
                    },
                    {
                        id: 'commandes',
                        label: 'monte.congelation.commandes',
                        active: false,
                        href : 'CongelationCommande'
                    },
                    {
                        id: 'expedition',
                        label: 'monte.congelation.expeditions',
                        active: false,
                        href : 'CongelationExpedition'
                    },
                    {
                        id: 'statistiques',
                        label: 'monte.congelation.statistiques',
                        active: false,
                        href : 'CongelationStatistiques'
                    },
                    {
                        id: 'statistiques_lot',
                        label: 'monte.congelation.statistiques_lot',
                        active: false,
                        href : 'CongelationStatistiquesLot'
                    },
                    {
                        id: 'cuve_shipped',
                        label: 'monte.congelation.cuve_shipped',
                        active: false,
                        href : 'CongelationCuveShipped'
                    }
				]
			}
		},
		components: {
            HeaderTab: () => import('@/components/HeaderTab'),
		}
	}
</script>
